import React from 'react';
import Contact from './Contact';

/**
 * Footer component.
 *
 * @returns footer
 */
const Footer = () => (
	<footer id="contact" className="app-footer">
		<h2>Contact Me</h2>
		<Contact />
	</footer>
);
export default Footer;
